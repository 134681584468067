.tilty {
    position: relative;
    width: w-full;
    height: 500px;
    background-image: url(nyc.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform-style: preserve-3d;
    transform: perspective(1000px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .inner {
    display: inline-block;
    transform: translateZ(60px);
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 1.5rem;
    color: white;
    letter-spacing: 0.15rem;
  }